import React from "react";
import { Link } from "react-router-dom";
import coupleImg1 from "../../images/couple/1.jpg";
import coupleImg2 from "../../images/couple/2.jpg";
import bg from "../../images/couple/image-bg.svg";
import { connect } from "react-redux";
import { capitalize, getHashtag, replaceString } from "../../utils/string";
import SectionTitle from "../SectionTitle";
import { welcomeMessageTitle } from "../../utils/constant";
import Hashtag from "../Hashtag/Hashtag";
import { Fade, Slide } from "react-awesome-reveal";
import { getCloudinaryFullPath } from "../../utils";

const CoupleSection2 = ({ bride = {}, groom = {} }) => {
  // const replaceObj = {
  //   grandfather:groom?.grandFatherName : bride.grandFatherName,
  //   grandmother:
  //     inviteSide === "groom" ? groom?.grandMotherName : bride.grandMotherName,
  // };

  // const subTitle =
  // replaceObj.grandfather && replaceObj.grandmother
  // ?
  // replaceString(welcomeMessageTitle, replaceObj);
  // : "";

  return (
    <section className="wpo-couple-section section-padding section" id="couple">
      <div className="container">
        {/* <SectionTitle
            s2Class="mBtm30"
            subTitle={""}
            MainTitle={
              "We request the honor of your presence as we unite our lives in marriage"
            }
          /> */}
        <div className={`couple-section-message`}>
          {/* <span>{subTitle}</span> */}
          <h2>
            We request the honor of your presence as we unite our lives in
            marriage
          </h2>
        </div>
        <Hashtag />
        <div className="couple-area clearfix ptp30">
          <div className="row align-items-center">
            <div className="col col-md-12 col-12">
              <div className="couple-item">
                <Slide direction="left" duration="1000" triggerOnce="true">
                  <div className="couple-img-wrap">
                    <div className="couple-img">
                      <img
                        className="coupleImage"
                        src={getCloudinaryFullPath(bride?.photo)}
                        alt=""
                      />
                    </div>
                    <div className="c-shape">
                      <img src={bg} alt="" />
                    </div>
                  </div>
                </Slide>
                <div className="couple-text">
                  <h3>{capitalize(bride?.name)}</h3>
                  <p>
                    <span>D/o</span> {capitalize(bride?.fatherName)} and{" "}
                    {capitalize(bride?.motherName)} <br /> From{" "}
                    {bride?.nativePlace} <br />
                    {bride?.phone && <i className="fi flaticon-phone-call" />}
                    {bride?.phone}
                  </p>
                  <p>{bride.about || ""}</p>
                </div>
              </div>
            </div>
            <div className="col col-md-12 col-12">
              <div className="couple-item">
                <div className="coupleSection2Groom">
                  <Slide direction="right" duration="1000" triggerOnce="true">
                    <div className="couple-img-wrap">
                      <div className="couple-img">
                        <img
                          className="coupleImage"
                          src={getCloudinaryFullPath(groom?.photo)}
                          alt=""
                        />
                      </div>
                      <div className="c-shape">
                        <img src={bg} alt="" />
                      </div>
                    </div>
                  </Slide>
                </div>
                <div className="couple-text">
                  <h3>{capitalize(groom?.name)}</h3>
                  <p>
                    S/o {capitalize(groom?.fatherName)} and{" "}
                    {capitalize(groom?.motherName)} <br /> From{" "}
                    {groom?.nativePlace} <br />
                    {groom?.phone && <i className="fi flaticon-phone-call" />}
                    {groom?.phone}
                  </p>
                  <p>{groom.about || ""}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shape-1 coupleSectionImg">
        <svg
          viewBox="0 0 1920 692"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.1">
            <path
              className="stroke-color"
              d="M-11 689C176.333 697 609 669.4 841 495L1111 279C1263.67 154.333 1640.6 -71.0002 1927 24.9998"
              stroke=""
              strokeWidth="2"
            />
            <path
              d="M-11 689C176.333 697 609 669.4 841 495L1111 279C1263.67 154.333 1640.6 -71.0002 1927 24.9998"
              stroke="black"
              strokeOpacity="0.2"
              strokeWidth="2"
            />
          </g>
          <g opacity="0.1">
            <path
              className="stroke-color"
              d="M1927 689C1739.67 697 1307 669.4 1075 495L805 279C652.333 154.333 275.4 -71.0002 -11 24.9998"
              stroke=""
              strokeWidth="2"
            />
            <path
              d="M1927 689C1739.67 697 1307 669.4 1075 495L805 279C652.333 154.333 275.4 -71.0002 -11 24.9998"
              stroke="black"
              strokeOpacity="0.2"
              strokeWidth="2"
            />
          </g>
          <path
            className="fill-color"
            d="M879 397C501.4 54.5998 135 31.6665 -1 62.9998V649C579.8 636.2 827.667 475.667 879 397Z"
            fill=""
          />
          <path
            className="fill-color"
            d="M1041 397C1418.6 54.5998 1785 31.6665 1921 62.9998V649C1340.2 636.2 1092.33 475.667 1041 397Z"
            fill=""
          />
        </svg>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    bride: state.inviteDetails.invite?.bride,
    groom: state.inviteDetails.invite?.groom,
  };
};

export default connect(mapStateToProps)(CoupleSection2);
