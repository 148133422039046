import { Fade } from "react-awesome-reveal";
import SectionTitle from "../../components/SectionTitle";
import bannerImage from "../../images/birthday/Jbanner.jpeg";

const Section3 = (props) => {
  return (
    <section className="wpo-event-section-s4 section-padding" id="event">
      <div className="container" style={{ minHeight: 0 }}>
        <SectionTitle subTitle={"You are Invited"} MainTitle={"When & Where"} />
        <div className="wpo-event-wrap">
          <div className="row">
            <div className="col col-lg-4 col-md-6 col-12">
              <Fade delay={100} duration={500} triggerOnce="true">
                <div className="wpo-event-item">
                  <div className="wpo-event-text">
                    <div className="title">
                      <h2>Dhundh Ceremony</h2>
                    </div>
                    <ul>
                      <li>
                        Saturday, 15 March. 2025 <br /> 1:30 PM Onwards
                      </li>
                      <li>
                        North Richmond Community Centre
                        <br />
                        33 William Street, North Richmond, NSW 2754
                      </li>
                      <li>
                        <i className="fi flaticon-phone-call" /> 04907 80625
                        <br />
                        <i className="fi flaticon-phone-call" /> 04907 77348
                      </li>
                    </ul>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="quoteContainer section-padding">
        <div className="content section">
          <div className="photos">
            <Fade delay={400} duration={1000} triggerOnce="true">
              <div className="thumbnails">
                <img src={bannerImage} alt="quote-bg" />
              </div>
            </Fade>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default Section3;
