export const EternalGraceData = {
  defaultData: true,
  eventType: 1,
  bannerPhoto: "v1734597223/DSC04138_3_lkxlyx.jpg",
  couplePhoto: "v1734358512/pexels-anntarazevich-5119616_gjgds6.jpg",
  phone: "123-456-7890",
  homeAddress: "Marine Lines, Mumbai, India",
  events: [
    {
      name: "Haldi Ceremony",
      dateAndTime: {
        date: "Tuesday, 17 Sep. 2025",
        time: "04:00 PM",
        dateStringTZ: "2024-09-17T10:30:00.000+00:00",
      },
      venue: "The Taj Mahal Palace, Mumbai, India",
      theme: "Flower shower",
      photo:
        "https://res.cloudinary.com/dxedclcqu/image/upload/v1720252677/static-assets/haldi_mqk4mw.svg",
    },
    {
      name: "Wedding Ceremony",
      dateAndTime: {
        date: "Tuesday, 17 Sep. 2025",
        time: "04:00 PM",
        dateStringTZ: "2024-09-17T10:30:00.000+00:00",
      },
      venue: "The Taj Mahal Palace, Mumbai, India",
      theme: "Pastels",
      photo:
        "https://res.cloudinary.com/dxedclcqu/image/upload/v1720252678/static-assets/card_q7crio.svg",
    },
    {
      name: "Reception",
      dateAndTime: {
        date: "Tuesday, 17 Sep. 2025",
        time: "04:00 PM",
        dateStringTZ: "2024-09-17T10:30:00.000+00:00",
      },
      venue: "The Taj Mahal Palace, Mumbai, India",
      theme: "Bollywood",
      photo:
        "https://res.cloudinary.com/dxedclcqu/image/upload/v1720252677/static-assets/toast_o7tsgn.svg",
    },
  ],
  bride: {
    name: "Astha Shah",
    motherName: "Amita Shah",
    fatherName: "Kalyanak Shah",
    nativePlace: "Jaipur, Rajasthan",
    photo: "v1734502798/DSC04181_2_kpwftx.jpg",
    about:
      "Astha is a landscape architect with a passion for creating serene, beautiful spaces.",
    phone: "2423534534",
  },
  groom: {
    name: "Pratik Jain",
    motherName: "Pooja Jain",
    fatherName: "Pankaj Jain",
    nativePlace: "Udaipur, Rajasthan",
    photo: "v1734501967/DSC04431_2_zrhmyo.jpg",
    about:
      "Pratik is an accomplished software engineer known for his analytical mind and innovative spirit.",
    phone: "2423534534",
  },
  quote:
    "Love is the essence that binds two hearts, and marriage is the celebration that brings those hearts together forever.",
  quotePhoto:
    "https://res.cloudinary.com/dxedclcqu/image/upload/v1726334513/pexels-andre-furtado-43594-1417255_a9cnmj.jpg",
  venue: {
    address: "The Taj Mahal Palace, Mumbai, India",
    latitude: "18.9217",
    longitude: "72.8332",
    phone: "123-456-7890",
  },
  bestComplimentsFrom:
    "Mr. Dharma Dugal - Mrs. Sunita Dugal,\nMr. Harsh Dugal - Mrs. Uma Dugal,\nMr. Kalyan Dugal - Mrs. Navya Dugal,\nMr. Jitendra Dugal - Mrs. Kalpana Dugal\n(Bhaiya - Bhabhi)",
  invitingWithGreatPleasure:
    "Mr. Vivan Mehta - Mrs. Aashita Mehta,\nMr. Rajesh Mehta - Mrs. Malati Mehta,\nMr. Amit Doshi - Mrs. Nupur Doshi,\nMr. Ayan Doshi - Mrs. Shreya Doshi",
  warmRegards:
    "Mr. Vijay Ahuja - Mrs. Namrata Ahuja,\nMr. Narendra Ahuja - Mrs. Supriya Ahuja,\nMr. Sudhir Ahuja - Mrs. Dipti Ahuja,\nMr. Anupam Ahuja - Mrs. Sangeeta Jain",
};
