import { Slide } from "react-awesome-reveal";
import image2 from "../../images/birthday/Jdad.JPG";
import image3 from "../../images/birthday/Jmom.JPG";
export default function Section5() {
  return (
    <>
      <Slide
        direction="right"
        duration="1000"
        triggerOnce="true"
        className="testimonial-card"
      >
        <div className="testimonial-card__content pink-bg ">
          <div className="testimonial-card__image">
            <img
              src={`${image3}?height=600&width=600`}
              alt="Happy person holding a cake at an outdoor celebration"
            />
          </div>
          <div className="testimonial-card__text">
            <blockquote className="testimonial-card__quote">
              From the moment I first felt your tiny kick, my heart overflowed
              with love. You are the most precious gift, my darling son. I
              cherish every moment we share, my tiny hero. I'm so blessed to be
              your mom.
            </blockquote>
            <div className="testimonial-card__author">
              <h3 className="testimonial-card__author-name">
                Rashmi Prajapati
              </h3>
              <p className="testimonial-card__author-title">- Dheer's Mom</p>
            </div>
          </div>
        </div>
      </Slide>
      <Slide
        direction="left"
        duration="1000"
        triggerOnce="true"
        className="testimonial-card"
      >
        <div className="testimonial-card__content">
          <div className="testimonial-card__image">
            <img
              src={`${image2}?height=600&width=600`}
              alt="Happy person holding a cake at an outdoor celebration"
            />
          </div>
          <div className="testimonial-card__text">
            <blockquote className="testimonial-card__quote">
              From the moment I held you in my arms, my heart was yours forever.
              I promise to always be there for you, my little champ. Watching
              you grow is the greatest gift. I'm so proud to be your Dad.
            </blockquote>
            <div className="testimonial-card__author">
              <h3 className="testimonial-card__author-name">
                Suresh Prajapati
              </h3>
              <p className="testimonial-card__author-title">- Dheer's Dad</p>
            </div>
          </div>
        </div>
      </Slide>
    </>
  );
}
