import React from "react";
// import { Link } from "react-router-dom";
// import coupleImg1 from "../../images/couple/3.jpg";
import coupleImg2 from "../../images/couple/couple-flower.png";
// import vector1 from "../../images/couple/vector-1.svg";
// import vector2 from "../../images/couple/vector-2.svg";
import { connect } from "react-redux";
import { getCloudinaryFullPath } from "../../utils";
import Hashtag from "../Hashtag/Hashtag";
import { capitalize } from "../../utils/string";

const CoupleSection3 = ({ couplePhoto, bride = {}, groom = {} }) => {
  return (
    <section className="wpo-couple-section-s3 section-padding" id="couple">
      <div className="container-fluid">
        <div className={`couple-section-message`}>
          <h2>
            We request the honor of your presence as we unite our lives in
            marriage
          </h2>
        </div>
        <Hashtag />
        <div className="couple-area clearfix">
          <div className="row align-items-center">
            <div className="col col-xl-3 col-lg-4 col-12">
              <div className="text-grid">
                {/* <div className="vector">
                  <img src={vector1} alt="" />
                </div> */}
                <h3>{capitalize(groom?.name)}</h3>
                <p>{groom.about || ""}</p>
                <p>
                  <span>D/o</span> {capitalize(groom?.fatherName || "")} and{" "}
                  {capitalize(groom?.motherName || "")} <br /> From{" "}
                  {groom?.nativePlace} <br />
                  {groom?.phone && <i className="fi flaticon-phone-call" />}
                  {groom?.phone}
                </p>
                {/* <div className="social">
                  <ul>
                    <li>
                      <Link to="/home-3">
                        <i className="ti-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/home-3">
                        <i className="ti-twitter-alt"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/home-3">
                        <i className="ti-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col col-xl-6 col-lg-4 col-12">
              <div className="middle-couple-pic">
                <div className="middle-couple-pic-inner">
                  <img src={getCloudinaryFullPath(couplePhoto)} alt="" />
                </div>
                <div className="couple-flower">
                  <img src={coupleImg2} alt="" />
                </div>
              </div>
            </div>
            <div className="col col-xl-3 col-lg-4 col-12">
              <div className="text-grid">
                {/* <div className="vector">
                  <img src={vector2} alt="" />
                </div> */}
                <h3>{capitalize(bride?.name)}</h3>
                <p>{bride.about || ""}</p>
                <p>
                  <span>D/o</span> {capitalize(bride?.fatherName || "")} and{" "}
                  {capitalize(bride?.motherName || "")} <br /> From{" "}
                  {bride?.nativePlace} <br />
                  {bride?.phone && <i className="fi flaticon-phone-call" />}
                  {bride?.phone}
                </p>
                {/* <div className="social">
                  <ul>
                    <li>
                      <Link to="/home-3">
                        <i className="ti-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/home-3">
                        <i className="ti-twitter-alt"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/home-3">
                        <i className="ti-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    bride: state.inviteDetails.invite?.bride,
    groom: state.inviteDetails.invite?.groom,
    couplePhoto: state.inviteDetails.invite?.couplePhoto,
  };
};
export default connect(mapStateToProps)(CoupleSection3);
