import React from "react";
import { connect } from "react-redux";
import { Slide } from "react-awesome-reveal";
import { Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import sImg1 from "../../images/wedding-date/1.png";
import sImg2 from "../../images/wedding-date/2.png";
import BirthdayBanner from "../../images/birthday/colors.jpg";

import { getDayDiffFromToday } from "../../utils";
import TimeCountDown from "../../components/countdown";

const Section1 = ({ bannerPhoto }) => {
  const eventDate = new Date("03/15/25");

  const daysLeft = getDayDiffFromToday();
  const countDownDate = eventDate;

  return (
    <>
      <section className="wpo-box-style">
        <div className="wpo-hero-slider">
          <h2 className="hidden">some</h2>
          <div className="swiper-container" style={{ height: "100%" }}>
            <div className="swiper-wrapper">
              <div
                className="slide-inner slide-bg-image zoom"
                style={{
                  backgroundImage: `url(${BirthdayBanner})`,
                }}
              ></div>
              {/* <Swiper
              // install Swiper modules
              modules={[Pagination, A11y]}
              spaceBetween={0}
              slidesPerView={1}
              loop={true}
              pagination={{ clickable: true }}
              speed={1800}
              parallax={true}
            >
              <SwiperSlide>
                <div
                  className="slide-inner slide-bg-image"
                  style={{ backgroundImage: `url(${bannerPhoto})` }}
                ></div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="slide-inner slide-bg-image"
                  style={{ backgroundImage: `url(${bannerPhoto})` }}
                ></div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="slide-inner slide-bg-image"
                  style={{ backgroundImage: `url(${bannerPhoto})` }}
                ></div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="slide-inner slide-bg-image"
                  style={{ backgroundImage: `url(${bannerPhoto})` }}
                ></div>
              </SwiperSlide>
              ...
            </Swiper> */}
            </div>
          </div>
        </div>
      </section>
      <Slide direction="up" delay={100} duration={600} triggerOnce="true">
        <section className="wpo-hero-wedding-date">
          <div className="wpo-wedding-date-inner">
            <span>You are invited to</span>
            <h2>Dheer's Dhundh Ceremony</h2>
            {/* <TypeWriter
              weddingDate={moment(countDownDate).format("MMMM DD, YYYY")}
            /> */}
            <button class="date-button">15th March 2025</button>
            <br />

            <span className="shape">
              <img src={sImg1} alt="" />{" "}
            </span>
            {countDownDate && (
              <div className="row">
                <div className="col col-xs-12">
                  <div className="clock-grids">
                    <div id="clock">
                      <div id="clock">
                        <TimeCountDown weddingDate={countDownDate} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="shape-1">
            <img src={sImg2} alt="" />
          </div>
          <div className="shape-2">
            <img src={sImg2} alt="" />
          </div>
        </section>
      </Slide>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bannerPhoto: state.inviteDetails.invite?.bannerPhoto,
  };
};
export default connect(mapStateToProps)(Section1);
