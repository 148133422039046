import React, { useEffect } from "react";
import { Slide } from "react-awesome-reveal";

import { Link } from "react-router-dom";
import coupleImg1 from "../../images/couple/4.jpg";
import coupleImg2 from "../../images/couple/5.jpg";
import SectionTitle from "../SectionTitle";
import { connect } from "react-redux";
import { getHashtag, replaceString } from "../../utils/string";
import { welcomeMessageTitle } from "../../utils/constant";
import { capitalize } from "@mui/material";
import Hashtag from "../Hashtag/Hashtag";
import coupleImg4 from "../../images/couple/right-invitee.svg";
import { getCloudinaryFullPath } from "../../utils";

const CoupleSection4 = ({ bride = {}, groom = {} }) => {
  // const replaceObj = {
  //   grandfather:
  //     inviteSide === "groom" ? groom?.grandFatherName : bride.grandFatherName,
  //   grandmother:
  //     inviteSide === "groom" ? groom?.grandMotherName : bride.grandMotherName,
  // };

  // const subTitle =
  // replaceObj.grandfather && replaceObj.grandmother
  // ?
  // replaceString(welcomeMessageTitle, replaceObj);
  // : "";
  // const hashtag = getHashtag(bride.name, groom.name);

  return (
    <section
      className="wpo-couple-section-s4 section-padding section"
      id="couple"
    >
      <div className="container-fluid">
        <div className={`couple-section-message`}>
          {/* <span>{subTitle}</span> */}
          <h2>
            We request the honor of your presence as we unite our lives in
            marriage
          </h2>
        </div>
        <Hashtag />
        <div className="couple-area clearfix">
          <div className="row align-items-center">
            <div className="col col-xl-3 col-lg-4 col-12">
              <div className="text-grid">
                <h3>{capitalize(bride?.name)}</h3>
                <p>
                  <span>D/o</span> {capitalize(bride?.fatherName)} and{" "}
                  {capitalize(bride?.motherName)} <br /> From{" "}
                  {bride?.nativePlace} <br />
                  {bride?.phone && <i className="fi flaticon-phone-call" />}
                  {bride?.phone}
                </p>
                <p>{bride.about || ""}</p>
                {/* <div className="social">
                  <ul>
                    <li>
                      <Link to="/home3">
                        <i className="ti-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/home3">
                        <i className="ti-twitter-alt"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/home3">
                        <i className="ti-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col col-xl-6 col-lg-4 col-12" style={{ zIndex: 1 }}>
              <div className="middle-couple-pic-wrap">
                <div className="middle-couple-pic">
                  <Slide direction="left" duration="1000" triggerOnce="true">
                    <div className="middle-couple-pic-inner">
                      <img
                        className="zoom"
                        src={getCloudinaryFullPath(bride?.photo)}
                        alt=""
                      />
                    </div>
                  </Slide>
                </div>
                <div className="middle-couple-pic">
                  <Slide direction="right" duration="1000" triggerOnce="true">
                    <div className="middle-couple-pic-inner">
                      <img
                        className="zoom"
                        src={getCloudinaryFullPath(groom?.photo)}
                        alt=""
                      />
                    </div>
                  </Slide>
                </div>
              </div>
            </div>
            <div className="col col-xl-3 col-lg-4 col-12">
              <div className="text-grid">
                <h3>{capitalize(groom?.name)}</h3>
                <p>
                  S/o {capitalize(groom?.fatherName)} and{" "}
                  {capitalize(groom?.motherName)} <br /> From{" "}
                  {groom?.nativePlace} <br />
                  {groom?.phone && <i className="fi flaticon-phone-call" />}
                  {groom?.phone}
                </p>
                <p>{groom.about || ""}</p>
                {/* <div className="social">
                  <ul>
                    <li>
                      <Link to="/home3">
                        <i className="ti-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/home3">
                        <i className="ti-twitter-alt"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/home3">
                        <i className="ti-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right-shape">
        <img src={coupleImg4} alt="" />
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    bride: state.inviteDetails.invite?.bride,
    groom: state.inviteDetails.invite?.groom,
  };
};

export default connect(mapStateToProps)(CoupleSection4);
