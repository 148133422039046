import React, { useEffect, useRef, useState } from "react";
// import Navbar from "../../components/Navbar/Navbar";
import Hero2 from "../../components/hero2/hero2";
import CoupleSection2 from "../../components/CoupleSection2/CoupleSection2";
// import StorySection2 from "../../components/StorySection2/StorySection2";
// import PortfolioSection from "../../components/PortfolioSection";
// import RsvpSection from "../../components/RsvpSection/RsvpSection";
import EventSection from "../../components/EventSection/EventSection";
// import BlogSection from "../../components/BlogSection/BlogSection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import VenueSection from "../../components/VenueSection/VenueSection";
import InviteeSection from "../../components/InviteeSection/InviteeSection";
import MyParticlesComponent from "../ParticlesComponent/ParticlesComponent";
import EventSection4 from "../../components/EventSection4/EventSection4";
import QuoteSection from "../../components/QuoteSection/QuoteSection";
import Hero3 from "../../components/hero3/hero3";
import WeddingDate from "../../components/WeddingDate/WeddingDate";
import useScrollDirection from "../../hooks/useScrollDirection";
// import RsvpSectionS3 from "../../components/RsvpSectionS3/RsvpSectionS3";
// import bg from "../../images/rsvp/bg.jpg";

const ModernChic = () => {
  const containerRef = useRef(null);
  // const scrollDirection = useScrollDirection(containerRef);

  // const [snapEnabled, setSnapEnabled] = useState(true);

  // useEffect(() => {
  //   if (scrollDirection === "down") {
  //     setSnapEnabled(true);
  //   } else {
  //     setSnapEnabled(false);
  //   }
  // }, [scrollDirection]);

  // useEffect(() => {
  //   const container = containerRef.current;

  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       const { top, bottom } = entry.boundingClientRect;
  //       const viewportHeight = window.innerHeight;
  //       console.log(bottom, viewportHeight, "===== bottom");

  //       if (bottom > viewportHeight + 500) {
  //         setSnapEnabled(true);
  //       } else {
  //         setSnapEnabled(false);
  //       }
  //     },
  //     { threshold: [0] } // Observe the element’s visibility
  //   );

  //   const sections = container.querySelectorAll(".section");
  //   sections.forEach((section) => observer.observe(section));

  //   return () => {
  //     sections.forEach((section) => observer.unobserve(section));
  //   };
  // }, []);

  return (
    <div ref={containerRef} className={`fadeInSlow scroll-container`}>
      <MyParticlesComponent />
      {/* <Navbar /> */}
      {/* <Hero2 /> */}
      <div className="section">
        <Hero3 />
        <WeddingDate />
      </div>
      <CoupleSection2 />
      {/* <EventSection imageWrapperShape="square" /> */}
      <QuoteSection />
      <EventSection4 haveBackground={true} />
      <VenueSection />
      <InviteeSection haveBackground={true} />
      {/* <StorySection2 /> */}
      {/* <PortfolioSection /> */}
      {/* <RsvpSection /> */}
      {/* <RsvpSectionS3 bg={bg} /> */}
      {/* <BlogSection /> */}
      <Footer footerClass={"wpo-site-footer-s2"} />
      <Scrollbar />
    </div>
  );
};

export default ModernChic;
