import { Fade } from "react-awesome-reveal";
import image2 from "../../images/birthday/Jbanner2.jpeg";
import bg from "../../images/birthday/colorBorder.png";

export default function Section2() {
  return (
    <>
      {/* <div className="container" style={{ background: "#f7f6f0" }}> */}
      <div className="container" style={{ backgroundImage: `url(${bg})` }}>
        <main className="main-content">
          <Fade
            className="image-container"
            delay={200}
            duration={600}
            triggerOnce="true"
          >
            <div className="blob-background"></div>
          </Fade>
          <h1 className="title">Dheer Prajapati</h1>

          <p className="description">
            With love and joy, we invite you to bless our little one on the
            occasion of the Dhundh Ceremony. Your presence and blessings will
            make this day unforgettable. Join us as we celebrate this special
            moment together!
          </p>
          {/* <p className="testimonial-card__author-title">- Jaisvi Darekar</p> */}
        </main>
      </div>
      <div className="quoteContainer section-padding">
        <div className="content section">
          <div className="photos">
            <Fade
              className="animate__animated animate__fadeInLeft"
              delay={200}
              duration={1000}
              triggerOnce="true"
            >
              <div className="thumbnails">
                <img src={image2} alt="quote-bg" />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
}
