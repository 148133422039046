import React from "react";
import { connect } from "react-redux";
import { getHashtag } from "../../utils/string";

const Hashtag = ({ bride = {}, groom = {}, hashtag }) => {
  const hashtagText = hashtag
    ? `#${hashtag}`
    : getHashtag(bride?.name, groom?.name);

  return (
    <div className="hashtagContainer">
      <span className="orangeText mBtm30">{hashtagText}</span>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    bride: state.inviteDetails.invite?.bride,
    groom: state.inviteDetails.invite?.groom,
    hashtag: state.inviteDetails.invite?.hashtag,
  };
};

export default connect(mapStateToProps)(Hashtag);
